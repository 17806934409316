.play {
    background : white;
    height:100vh;
    border-collapse: collapse;
    display:flex;
    justify-content: center;
    align-items: center;
}

.playB_close {
    display:flex;
    align-items: center;
    position: fixed;
    top:20px;
    left:20px;
    height:20px;
    transform:rotateZ(0deg);
    line-height:10px;
}


.playB_next:hover {
    cursor: pointer;
    background:#00b7ff;
    color:#0F084B;
}

.playB_cross {
    height:20px;
    width:20px;
    transition:0.8s;
}

.playB_cross:hover {
    transform:rotateZ(180deg);
}

.playB_close:hover {
    cursor:pointer;
}

.playB_left {
    color:#0F084B;
    width:90%;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.playB_title {
    color:#0F084B;
    width:100%;
    font-size:3vw;
    text-align:left;
    padding-left: 80px;
    margin-bottom: 20px;
    display:flex;
}

.playB_rules {
    color:#0F084B;
    width:100%;
    padding-top: 40px;
    font-size:2.5vw;
    text-align:center;
}

@media only screen and (min-width: 600px) {
    .playB_rules {
      font-size: 1.5vw;
      margin-bottom: 20px;
  
    }
  }

.playB_score {
    color:#0F084B;
    width:100%;
    font-size:5vw;
    text-align:center;
    margin-bottom: 40px; 
    transition:color 0.3s;
}

.playB_line {
    
    display:flex;
    flex-direction: row;
    width:90%;
    padding: 0 20px;
    align-items: center;
    border-collapse: collapse;
    margin:5px 0;
    position:relative;
}

.playB_ranking {

    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    transform:translateX(-50px);
    opacity: 0;
    overflow:"hidden";
}

.playB_right {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width:50%;
    height:100vh;
    background:lightblue;
}

.playB_presentation {
    display : flex;
    width:100%;
}

.playB_rank {
    left:0;
    margin:0;
    text-align: center;
    line-height: 38px;
    position:absolute;
    width:45px;
    height:40px;
    border-radius: 50%;
    font-weight: bold;
    background: white;
    border:1px solid rgb(0,0,0,0.1);
    z-index: 1;
}


.playB_name {
    overflow: hidden;
    background-color:rgba(2,136,247,1);
    background-image:linear-gradient(90deg, rgba(0,224,230,1) 0%, rgba(2,136,247,0) 100%);
    line-height: 36px;
    padding-left: 45px;
    text-align: left;
    color:rgb(15,8,75,1);;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    height:40px;
    width:100%;
    margin:0;
    transition:0.4s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.playB_next {
    padding:10px 20px;
    line-height:15px;
    width:fit-content;
    background:#0F084B;
    color:lightblue;
    border:none;
    opacity: 0;
    border-radius:3px;
    animation: fade-in 0.4s ease-in-out forwards;
    transition: 0.4s;
    margin-left: 45px;
}

.playB_finished {
    display:block;
    width:100%;
    
}


.playB_hover {
    background-color:rgb(255, 238, 0);
}

.playB_wrong {
    background-color:rgb(253, 46, 46);
}

.playB_success {
    background-color:rgb(166, 255, 0);
}

.playB_score_wrong {
    color:rgb(253,46,46);
}

.playB_score_success {
    color:rgb(166,255,0);
}

.playB_draggable {
    background-color:rgb(15,8,75,0.6);
    color:rgb(185, 239, 255);
    width: fit-content;
    font-size: 2.5vw;
    border-radius: 4px;
    margin:7px 10px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.4s;
    animation: fade-in 3s ease-in-out;
}

@media only screen and (min-width: 600px) {
    .playB_draggable {
      font-size: 1.2vw;
    }
  }

.playB_draggable:hover {
    background-color:rgb(15,8,75,1);
}

.playB_refresh {
    height: 20px;
    width: 20px;
    transition: 0.8s;

  }
  
.playB_refresh:hover {
    transform: rotateZ(180deg);
  }

.playB_responses {
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:80%;
    height:fit-content;
}