.info_title {
    width:350px;
}

.info_duo {
    width:350px;
    display : flex;
    flex-direction: row;
    align-items: center;
}

.info_duo .info_nom {
    width:300px;
}

.info_close {
    position:fixed;top:20px;left:20px;z-index:1;
}

.info_close:hover {
    cursor: pointer;
}

.info {
    text-align: center;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}