.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  width: 230px;
  height: 330px;
  margin: 15px 12px;
  border: lightgray 1px solid;
  border-radius: 6px;
}

.card_logo {
  height: 80px;
  object-fit: contain;
}

.card_title {
  height: 45px;
  width: 80%;
  font-size: large;
  text-align: center;
}

.card_select {
  height: 40px;
  width: 55%;
  background: rgb(
    36,
    34,
    34
  ); /*background: linear-gradient(45deg, rgba(233,101,13,1) 0%, rgba(255,202,76,1) 100%);*/
  color: white;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  transition: 0.4s;
}

.card_select:hover {
  cursor: pointer;
  width: 75%;
}
