body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.body {
  min-height:100vh;
  width:100%;
  margin:0;


}

.maclasse {
  background:black;
  color:white;
  font-size: 50px;
  width:200;
}

.app_credits {

  font-weight: 16px;
  position: absolute;
  bottom: 0%;
  opacity: 0;
  -webkit-animation: fadeIn 2s forwards ease-in-out 1.5s;
          animation: fadeIn 2s forwards ease-in-out 1.5s;
}

.app_credits:hover {
  cursor: pointer;
}

.app {
  height:100vh;
  position: relative;
}

.app_accueil {
  position: absolute;
  top:0;
  left:0;
  display:flex;
  align-items: center;
  justify-content: center;
  height:100vh;
  z-index: 1;
  -webkit-clip-path: circle(100%);
          clip-path: circle(100%);
  background-color: white;

}

.app_picture {
  display:none;

}

@media only screen and (min-width: 600px) {
  .app_picture {
    display:block;
    width:40%;
    object-fit: contain;
    margin-left: 20px;
  
  }
}

.app_left {
  width:90%
}

h1 {
  font-size: 70px;
  margin:0;
  color:rgb(56, 50, 50);
}

h2 {
  font-weight: normal;
  font-size: 30px;
}
h1,h2 {
  text-align: center;
}

.app_choice {
  width:100%;
  margin-top: 30px;
  display:flex;
  justify-content: space-between;
}
@media only screen and (min-width: 600px) {
  h1,h2 {
    text-align: left;
  }
  .app_left {
    width:30%;
  }
  .app_choice {
    width:70%;
  }
}



.app_buttons {
  border:none;
  background :rgb(56, 50, 50);
  color:white;
  height: 40px;
  border-radius: 3px;
  transition:0.5s;
  font-size: 21px;
}

.app_play {
  flex:1 1;
  margin-right: 10px;
}

.app_play {
  display:none;
}

.add {
  display:block;
  -webkit-animation: fadeIn 2s forwards ease-in-out 1.5s;
          animation: fadeIn 2s forwards ease-in-out 1.5s;
  opacity: 0;
  font-size: 15px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

@media only screen and (min-width: 600px) {
  .add {
    display:none;
  
  }
  .app_play {
    display:block;
  }
}

.app_explore {
  flex: 1 1;
  margin-left: 10px;
}

button:hover {
  background:black;
  flex:2 1;
  cursor: pointer;
}


/*! * * * * * * * * * * * * * * * * * * * *\  
  CSShake :: shake-horizontal
  v1.5.0
  CSS classes to move your DOM
  (c) 2015 @elrumordelaluz
  http://elrumordelaluz.github.io/csshake/
  Licensed under MIT
\* * * * * * * * * * * * * * * * * * * * */
.shake-horizontal {
    display: inherit;
    transform-origin: center center; }
  
  .shake-freeze,
  .shake-constant.shake-constant--hover:hover,
  .shake-trigger:hover .shake-constant.shake-constant--hover {
    -webkit-animation-play-state: paused;
            animation-play-state: paused; }
  
  .shake-freeze:hover,
  .shake-trigger:hover .shake-freeze, .shake-horizontal:hover,
  .shake-trigger:hover .shake-horizontal {
    -webkit-animation-play-state: running;
            animation-play-state: running; }
  
  @-webkit-keyframes shake-horizontal {
    12% {
      transform: translate(6px, 0) rotate(0); }
    24% {
      transform: translate(-7px, 0) rotate(0); }
    42% {
      transform: translate(5px, 0) rotate(0); }
    54% {
      transform: translate(7px, 0) rotate(0); }
    64% {
      transform: translate(8px, 0) rotate(0); }
    76% {
      transform: translate(-9px, 0) rotate(0); }
    84% {
      transform: translate(-5px, 0) rotate(0); }
    92% {
      transform: translate(2px, 0) rotate(0); }
    0%, 100% {
      transform: translate(0, 0) rotate(0); } }
  
  @keyframes shake-horizontal {
    12% {
      transform: translate(6px, 0) rotate(0); }
    24% {
      transform: translate(-7px, 0) rotate(0); }
    42% {
      transform: translate(5px, 0) rotate(0); }
    54% {
      transform: translate(7px, 0) rotate(0); }
    64% {
      transform: translate(8px, 0) rotate(0); }
    76% {
      transform: translate(-9px, 0) rotate(0); }
    84% {
      transform: translate(-5px, 0) rotate(0); }
    92% {
      transform: translate(2px, 0) rotate(0); }
    0%, 100% {
      transform: translate(0, 0) rotate(0); } }
 
.shake-horizontal, .shake-horizontal.shake-freeze, .shake-horizontal.shake-constant {
    -webkit-animation-name: shake-horizontal;
            animation-name: shake-horizontal;
    -webkit-animation-duration: 200ms;
            animation-duration: 200ms;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite; }
.play {
    background : white;
    height:100vh;
    border-collapse: collapse;
    display:flex;
    justify-content: center;
    align-items: center;
}

.playB_close {
    display:flex;
    align-items: center;
    position: fixed;
    top:20px;
    left:20px;
    height:20px;
    transform:rotateZ(0deg);
    line-height:10px;
}


.playB_next:hover {
    cursor: pointer;
    background:#00b7ff;
    color:#0F084B;
}

.playB_cross {
    height:20px;
    width:20px;
    transition:0.8s;
}

.playB_cross:hover {
    transform:rotateZ(180deg);
}

.playB_close:hover {
    cursor:pointer;
}

.playB_left {
    color:#0F084B;
    width:90%;
    display:flex;
    align-items: center;
    flex-direction: column;
}

.playB_title {
    color:#0F084B;
    width:100%;
    font-size:3vw;
    text-align:left;
    padding-left: 80px;
    margin-bottom: 20px;
    display:flex;
}

.playB_rules {
    color:#0F084B;
    width:100%;
    padding-top: 40px;
    font-size:2.5vw;
    text-align:center;
}

@media only screen and (min-width: 600px) {
    .playB_rules {
      font-size: 1.5vw;
      margin-bottom: 20px;
  
    }
  }

.playB_score {
    color:#0F084B;
    width:100%;
    font-size:5vw;
    text-align:center;
    margin-bottom: 40px; 
    transition:color 0.3s;
}

.playB_line {
    
    display:flex;
    flex-direction: row;
    width:90%;
    padding: 0 20px;
    align-items: center;
    border-collapse: collapse;
    margin:5px 0;
    position:relative;
}

.playB_ranking {

    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    transform:translateX(-50px);
    opacity: 0;
    overflow:"hidden";
}

.playB_right {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width:50%;
    height:100vh;
    background:lightblue;
}

.playB_presentation {
    display : flex;
    width:100%;
}

.playB_rank {
    left:0;
    margin:0;
    text-align: center;
    line-height: 38px;
    position:absolute;
    width:45px;
    height:40px;
    border-radius: 50%;
    font-weight: bold;
    background: white;
    border:1px solid rgb(0,0,0,0.1);
    z-index: 1;
}


.playB_name {
    overflow: hidden;
    background-color:rgba(2,136,247,1);
    background-image:linear-gradient(90deg, rgba(0,224,230,1) 0%, rgba(2,136,247,0) 100%);
    line-height: 36px;
    padding-left: 45px;
    text-align: left;
    color:rgb(15,8,75,1);;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    height:40px;
    width:100%;
    margin:0;
    transition:0.4s;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.playB_next {
    padding:10px 20px;
    line-height:15px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    background:#0F084B;
    color:lightblue;
    border:none;
    opacity: 0;
    border-radius:3px;
    -webkit-animation: fade-in 0.4s ease-in-out forwards;
            animation: fade-in 0.4s ease-in-out forwards;
    transition: 0.4s;
    margin-left: 45px;
}

.playB_finished {
    display:block;
    width:100%;
    
}


.playB_hover {
    background-color:rgb(255, 238, 0);
}

.playB_wrong {
    background-color:rgb(253, 46, 46);
}

.playB_success {
    background-color:rgb(166, 255, 0);
}

.playB_score_wrong {
    color:rgb(253,46,46);
}

.playB_score_success {
    color:rgb(166,255,0);
}

.playB_draggable {
    background-color:rgb(15,8,75,0.6);
    color:rgb(185, 239, 255);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 2.5vw;
    border-radius: 4px;
    margin:7px 10px;
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.4s;
    -webkit-animation: fade-in 3s ease-in-out;
            animation: fade-in 3s ease-in-out;
}

@media only screen and (min-width: 600px) {
    .playB_draggable {
      font-size: 1.2vw;
    }
  }

.playB_draggable:hover {
    background-color:rgb(15,8,75,1);
}

.playB_refresh {
    height: 20px;
    width: 20px;
    transition: 0.8s;

  }
  
.playB_refresh:hover {
    transform: rotateZ(180deg);
  }

.playB_responses {
    flex-wrap: wrap;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:80%;
    height:-webkit-fit-content;
    height:-moz-fit-content;
    height:fit-content;
}
.play {
  background: white;
  height: 100vh;
  border-collapse: collapse;
  display: flex;
  justify-content: center;
  align-items: center;
}



.playR_next:hover {
  cursor: pointer;
  background: rgb(240, 255, 206);
  color: #280004;
}

.disable {
  display:none;
}

.playR_cross {
  height: 20px;
  width: 20px;
  transition: 0.8s;
}
.playR_close {
    position: fixed;
    top: 20px;
    left: 20px;
    height: 20px;
    display:flex;
    align-items: center;
    transform: rotateZ(0deg);
    line-height: 10px;
  }

.playR_refresh {
    height: 20px;
    width: 20px;
    transition: 0.8s;
  }
  
.playR_refresh:hover {
    transform: rotateZ(180deg);
  }

.playR_cross:hover {
  transform: rotateZ(180deg);
}

.playR_close:hover {
  cursor: pointer;
}

.playR_left {
  color: #280004;
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.playR_title {
  color: #280004;
  width: 100%;
  font-size: 3vw;
  text-align: left;
  padding-left: 80px;
  margin-bottom: 20px;
  display: flex;
}

.playR_rules {
  color: #280004;
  width: 100%;
  padding-top: 40px;
  font-size: 2.5vw;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .playR_rules {
    font-size: 1.5vw;
    margin-bottom: 20px;

  }
}

.playR_score {
  color: #280004;
  width: 100%;
  font-size: 5vw;
  text-align: center;
  margin-bottom: 40px;
  transition: color 0.3s;
}

.playR_line {
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 0 20px;
  align-items: center;
  border-collapse: collapse;
  margin: 5px 0;
  position: relative;
}

.playR_ranking {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transform: translateX(-50px);
  opacity: 0;
  overflow: "hidden";
}

.playR_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 100vh;
  background: #a53f2b;
}

.playR_presentation {
  display: flex;
  width: 100%;
}

.playR_rank {
  left: 0;
  margin: 0;
  text-align: center;
  line-height: 38px;
  position: absolute;
  width: 45px;
  height: 40px;
  border-radius: 50%;
  font-weight: bold;
  background: white;
  border: 1px solid rgb(0, 0, 0, 0.1);
  z-index: 1;
}

.playR_name {
  overflow: hidden;
  background-color: rgba(165, 63, 43, 1);
  background-image: linear-gradient(
    90deg,
    rgb(255, 196, 79) 0%,
    rgba(2, 136, 247, 0) 100%
  );
  line-height: 40px;
  padding-left: 45px;
  text-align: left;
  color: rgb(15, 8, 75, 1);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 40px;
  width: 100%;
  margin: 0;
  transition: 0.4s;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.playR_next {
  padding: 10px 20px;
  line-height: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #280004;
  color: rgb(240, 255, 206);
  border: rgb(40, 0, 4) 1px solid;
  opacity: 0;
  border-radius: 3px;
  -webkit-animation: fade-in 0.4s ease-in-out forwards;
          animation: fade-in 0.4s ease-in-out forwards;
  transition: 0.4s;
  margin-left: 45px;
}

.playR_finished {
  display: block;
  width: 100%;
}

.playR_hover {
  background-color: rgb(255, 238, 0);
}

.playR_wrong {
  background-color: rgb(253, 46, 46);
}

.playR_success {
  background-color: rgb(166, 255, 0);
}

.playR_score_wrong {
  color: rgb(253, 46, 46);
}

.playR_score_success {
  color: rgb(166, 255, 0);
}

.playR_draggable {
  background-color: rgb(40, 0, 4, 0.6);
  color: rgb(240, 255, 206);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 2.5vw;
  border-radius: 4px;
  margin: 7px 10px;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.4s;
  -webkit-animation: fade-in 3s ease-in-out;
          animation: fade-in 3s ease-in-out;
}

@media only screen and (min-width: 600px) {
  .playR_draggable {
    font-size: 1.2vw;
  }
}

.playR_draggable:hover {
  background-color: rgb(40, 0, 4, 1);
}

.playR_responses {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content;
  
}

.explorer {
    min-height:100vh;
    width:100%;
    background: white;
    display:flex;
    flex:wrap 1;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.explorer_close {
    position:fixed;top:20px;left:20px;z-index:1;
}

.explorer_close:hover {
    cursor: pointer;
}

.explorer_recherche {
    position:fixed;
    margin-top: 70px;
    background:white;
    width:90%;
    max-width: 600px;
    border:solid black 1px;
    border-radius: 3px;
    height:40px;
    display:flex;
    align-items: center;
}

@media only screen and (min-width: 600px) {
    .explorer_recherche {
        margin-top: 40px;
    }
}

.explorer_input {
    flex:9 1;
    padding-left: 10px;
}

.explorer_icon {
    flex:1 1;
    object-fit: contain;
}

.explorer_list {
    display:flex;
    justify-content: center;
    width:90%;
    flex-wrap: wrap;
    margin-top: 120px;
}
.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  width: 230px;
  height: 330px;
  margin: 15px 12px;
  border: lightgray 1px solid;
  border-radius: 6px;
}

.card_logo {
  height: 80px;
  object-fit: contain;
}

.card_title {
  height: 45px;
  width: 80%;
  font-size: large;
  text-align: center;
}

.card_select {
  height: 40px;
  width: 55%;
  background: rgb(
    36,
    34,
    34
  ); /*background: linear-gradient(45deg, rgba(233,101,13,1) 0%, rgba(255,202,76,1) 100%);*/
  color: white;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  transition: 0.4s;
}

.card_select:hover {
  cursor: pointer;
  width: 75%;
}

.show {
    background:white;
    min-height:90vh;
    width:100%;
    display:flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

.show_choices {
    display:flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 800px) {
    .show {
        min-height:95vh;
    }
}

.show_close {
    position:absolute;top:20px;left:20px;z-index: 6;
}


.show_close:hover {
    cursor: pointer;
}

.show_image {
    max-height:500px;
    padding-bottom: 10px;
    width:50%;
    object-fit: contain;
    opacity: 0;

}

.show_image {
    display:none;
}

@media only screen and (min-width: 800px) {
    .show_image {
        display:block;
    }
}

.show_middle {
    width: 95%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.show_top {
    opacity: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    width:93%;
    margin-top:50px;
    margin-bottom: 5px;
}

@media only screen and (min-width: 800px) {
    .show_top {
        margin-top:70px;
    }
}

.show_logo {
    height:100px;
    object-fit: contain;
}

.show_logo {
    display:none;
}

@media only screen and (min-width: 800px) {
    .show_logo {
        display:block;
    }
}

.show_title {
    padding-left: 20px;
    font-size: 20px;
    text-align: center;
}

@media only screen and (min-width: 800px) {
    .show_title {
        font-size: 30px;


    }
}

.show_line {
    
    display:flex;
    flex-direction: row;
    width:90%;
    padding: 0 20px;
    align-items: center;
    border-collapse: collapse;
    margin:5px 0;
    position:relative;
}

.show_ranking {

    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    transform:translateX(-50px);
    opacity: 0;
    overflow:"hidden";
}

.show_rank {
    left:0;
    margin:0;
    text-align: center;
    line-height: 38px;
    position:absolute;
    width:45px;
    height:40px;
    border-radius: 50%;
    font-weight: bold;
    background: white;
    border:1px solid rgb(0,0,0,0.1);
    z-index: 1;
}


.show_name {
    overflow: hidden;
    background-color:rgba(2,136,247,1);
    background-image:linear-gradient(90deg, rgba(0,224,230,1) 0%, rgba(2,136,247,0) 100%);
    line-height: 36px;
    padding-left: 45px;
    text-align: left;
    color:rgb(15,8,75,1);;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    height:40px;
    width:100%;
    margin:0;
    transition:0.4s;
}

.show_next {
    margin: 0 10px;
    margin-top: 30px;
    border-radius: 3px;
    border:black 1px solid;
    padding:6px 16px;
    transition:0.4s;
    margin-bottom: auto;
}

.show_play {
    display:none;
}
@media only screen and (min-width: 800px) {
    .show_top {
        margin-top: 10px;
    }
    .show_play {
        display:block;
    }
}

.show_next:hover {
    cursor: pointer;
    background:black;
    color:white;
}

.decide {
    position: absolute;
    top:0;
    z-index: 5;
    width:100%;
    height:100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}



.decide_right, .decide_left {
    width:48%;
    margin-top: 90px;
    border:4px lightgray solid;
    height:75%;
    display:flex;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
    background:rgb(0,0,0,0.95);
    position:relative;
}

.decide_rank {
    width:250px;
    height:50px;
    text-align: center;
    line-height:50px;
    border-radius: 5px;
    font-size: 23px;
    background:white;
    transition:0.4s;
    color:black;
}
.decide_rank:hover,.decide_guess:hover {
    cursor:pointer
}
.decide_rank:hover {
    color:white;
    background:black;
}

.decide_guess:hover {
    color:black;
    background:white;
    border:1px solid black;
}
.decide_guess {
    width:250px;
    transition:0.4s;
    height:50px;
    text-align: center;
    line-height:50px;
    border-radius: 5px;
    font-size: 23px;
    background:black;
    color:white
}

.decide_l , .decide_r {
    position: absolute;
    font-size: 120px;
    top:0;
    
}


.decide_l {
    right:0;
    color:white;
}

.decide_r {
    left:0;
    color:black;
}
.info_title {
    width:350px;
}

.info_duo {
    width:350px;
    display : flex;
    flex-direction: row;
    align-items: center;
}

.info_duo .info_nom {
    width:300px;
}

.info_close {
    position:fixed;top:20px;left:20px;z-index:1;
}

.info_close:hover {
    cursor: pointer;
}

.info {
    text-align: center;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
}
