.explorer {
    min-height:100vh;
    width:100%;
    background: white;
    display:flex;
    flex:wrap;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.explorer_close {
    position:fixed;top:20px;left:20px;z-index:1;
}

.explorer_close:hover {
    cursor: pointer;
}

.explorer_recherche {
    position:fixed;
    margin-top: 70px;
    background:white;
    width:90%;
    max-width: 600px;
    border:solid black 1px;
    border-radius: 3px;
    height:40px;
    display:flex;
    align-items: center;
}

@media only screen and (min-width: 600px) {
    .explorer_recherche {
        margin-top: 40px;
    }
}

.explorer_input {
    flex:9;
    padding-left: 10px;
}

.explorer_icon {
    flex:1;
    object-fit: contain;
}

.explorer_list {
    display:flex;
    justify-content: center;
    width:90%;
    flex-wrap: wrap;
    margin-top: 120px;
}