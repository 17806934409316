
.body {
  min-height:100vh;
  width:100%;
  margin:0;


}

.maclasse {
  background:black;
  color:white;
  font-size: 50px;
  width:200;
}

.app_credits {

  font-weight: 16px;
  position: absolute;
  bottom: 0%;
  opacity: 0;
  animation: fadeIn 2s forwards ease-in-out 1.5s;
}

.app_credits:hover {
  cursor: pointer;
}

.app {
  height:100vh;
  position: relative;
}

.app_accueil {
  position: absolute;
  top:0;
  left:0;
  display:flex;
  align-items: center;
  justify-content: center;
  height:100vh;
  z-index: 1;
  clip-path: circle(100%);
  background-color: white;

}

.app_picture {
  display:none;

}

@media only screen and (min-width: 600px) {
  .app_picture {
    display:block;
    width:40%;
    object-fit: contain;
    margin-left: 20px;
  
  }
}

.app_left {
  width:90%
}

h1 {
  font-size: 70px;
  margin:0;
  color:rgb(56, 50, 50);
}

h2 {
  font-weight: normal;
  font-size: 30px;
}
h1,h2 {
  text-align: center;
}

.app_choice {
  width:100%;
  margin-top: 30px;
  display:flex;
  justify-content: space-between;
}
@media only screen and (min-width: 600px) {
  h1,h2 {
    text-align: left;
  }
  .app_left {
    width:30%;
  }
  .app_choice {
    width:70%;
  }
}



.app_buttons {
  border:none;
  background :rgb(56, 50, 50);
  color:white;
  height: 40px;
  border-radius: 3px;
  transition:0.5s;
  font-size: 21px;
}

.app_play {
  flex:1;
  margin-right: 10px;
}

.app_play {
  display:none;
}

.add {
  display:block;
  animation: fadeIn 2s forwards ease-in-out 1.5s;
  opacity: 0;
  font-size: 15px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.7;
  }
}

@media only screen and (min-width: 600px) {
  .add {
    display:none;
  
  }
  .app_play {
    display:block;
  }
}

.app_explore {
  flex: 1;
  margin-left: 10px;
}

button:hover {
  background:black;
  flex:2;
  cursor: pointer;
}

