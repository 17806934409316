.play {
  background: white;
  height: 100vh;
  border-collapse: collapse;
  display: flex;
  justify-content: center;
  align-items: center;
}



.playR_next:hover {
  cursor: pointer;
  background: rgb(240, 255, 206);
  color: #280004;
}

.disable {
  display:none;
}

.playR_cross {
  height: 20px;
  width: 20px;
  transition: 0.8s;
}
.playR_close {
    position: fixed;
    top: 20px;
    left: 20px;
    height: 20px;
    display:flex;
    align-items: center;
    transform: rotateZ(0deg);
    line-height: 10px;
  }

.playR_refresh {
    height: 20px;
    width: 20px;
    transition: 0.8s;
  }
  
.playR_refresh:hover {
    transform: rotateZ(180deg);
  }

.playR_cross:hover {
  transform: rotateZ(180deg);
}

.playR_close:hover {
  cursor: pointer;
}

.playR_left {
  color: #280004;
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.playR_title {
  color: #280004;
  width: 100%;
  font-size: 3vw;
  text-align: left;
  padding-left: 80px;
  margin-bottom: 20px;
  display: flex;
}

.playR_rules {
  color: #280004;
  width: 100%;
  padding-top: 40px;
  font-size: 2.5vw;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .playR_rules {
    font-size: 1.5vw;
    margin-bottom: 20px;

  }
}

.playR_score {
  color: #280004;
  width: 100%;
  font-size: 5vw;
  text-align: center;
  margin-bottom: 40px;
  transition: color 0.3s;
}

.playR_line {
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 0 20px;
  align-items: center;
  border-collapse: collapse;
  margin: 5px 0;
  position: relative;
}

.playR_ranking {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  transform: translateX(-50px);
  opacity: 0;
  overflow: "hidden";
}

.playR_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  height: 100vh;
  background: #a53f2b;
}

.playR_presentation {
  display: flex;
  width: 100%;
}

.playR_rank {
  left: 0;
  margin: 0;
  text-align: center;
  line-height: 38px;
  position: absolute;
  width: 45px;
  height: 40px;
  border-radius: 50%;
  font-weight: bold;
  background: white;
  border: 1px solid rgb(0, 0, 0, 0.1);
  z-index: 1;
}

.playR_name {
  overflow: hidden;
  background-color: rgba(165, 63, 43, 1);
  background-image: linear-gradient(
    90deg,
    rgb(255, 196, 79) 0%,
    rgba(2, 136, 247, 0) 100%
  );
  line-height: 40px;
  padding-left: 45px;
  text-align: left;
  color: rgb(15, 8, 75, 1);
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  height: 40px;
  width: 100%;
  margin: 0;
  transition: 0.4s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.playR_next {
  padding: 10px 20px;
  line-height: 15px;
  width: fit-content;
  background: #280004;
  color: rgb(240, 255, 206);
  border: rgb(40, 0, 4) 1px solid;
  opacity: 0;
  border-radius: 3px;
  animation: fade-in 0.4s ease-in-out forwards;
  transition: 0.4s;
  margin-left: 45px;
}

.playR_finished {
  display: block;
  width: 100%;
}

.playR_hover {
  background-color: rgb(255, 238, 0);
}

.playR_wrong {
  background-color: rgb(253, 46, 46);
}

.playR_success {
  background-color: rgb(166, 255, 0);
}

.playR_score_wrong {
  color: rgb(253, 46, 46);
}

.playR_score_success {
  color: rgb(166, 255, 0);
}

.playR_draggable {
  background-color: rgb(40, 0, 4, 0.6);
  color: rgb(240, 255, 206);
  width: fit-content;
  font-size: 2.5vw;
  border-radius: 4px;
  margin: 7px 10px;
  padding: 10px 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.4s;
  animation: fade-in 3s ease-in-out;
}

@media only screen and (min-width: 600px) {
  .playR_draggable {
    font-size: 1.2vw;
  }
}

.playR_draggable:hover {
  background-color: rgb(40, 0, 4, 1);
}

.playR_responses {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
  height:fit-content;
  
}
