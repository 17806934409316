.show {
    background:white;
    min-height:90vh;
    width:100%;
    display:flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}

.show_choices {
    display:flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 800px) {
    .show {
        min-height:95vh;
    }
}

.show_close {
    position:absolute;top:20px;left:20px;z-index: 6;
}


.show_close:hover {
    cursor: pointer;
}

.show_image {
    max-height:500px;
    padding-bottom: 10px;
    width:50%;
    object-fit: contain;
    opacity: 0;

}

.show_image {
    display:none;
}

@media only screen and (min-width: 800px) {
    .show_image {
        display:block;
    }
}

.show_middle {
    width: 95%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.show_top {
    opacity: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    width:93%;
    margin-top:50px;
    margin-bottom: 5px;
}

@media only screen and (min-width: 800px) {
    .show_top {
        margin-top:70px;
    }
}

.show_logo {
    height:100px;
    object-fit: contain;
}

.show_logo {
    display:none;
}

@media only screen and (min-width: 800px) {
    .show_logo {
        display:block;
    }
}

.show_title {
    padding-left: 20px;
    font-size: 20px;
    text-align: center;
}

@media only screen and (min-width: 800px) {
    .show_title {
        font-size: 30px;


    }
}

.show_line {
    
    display:flex;
    flex-direction: row;
    width:90%;
    padding: 0 20px;
    align-items: center;
    border-collapse: collapse;
    margin:5px 0;
    position:relative;
}

.show_ranking {

    display:flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    transform:translateX(-50px);
    opacity: 0;
    overflow:"hidden";
}

.show_rank {
    left:0;
    margin:0;
    text-align: center;
    line-height: 38px;
    position:absolute;
    width:45px;
    height:40px;
    border-radius: 50%;
    font-weight: bold;
    background: white;
    border:1px solid rgb(0,0,0,0.1);
    z-index: 1;
}


.show_name {
    overflow: hidden;
    background-color:rgba(2,136,247,1);
    background-image:linear-gradient(90deg, rgba(0,224,230,1) 0%, rgba(2,136,247,0) 100%);
    line-height: 36px;
    padding-left: 45px;
    text-align: left;
    color:rgb(15,8,75,1);;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    height:40px;
    width:100%;
    margin:0;
    transition:0.4s;
}

.show_next {
    margin: 0 10px;
    margin-top: 30px;
    border-radius: 3px;
    border:black 1px solid;
    padding:6px 16px;
    transition:0.4s;
    margin-bottom: auto;
}

.show_play {
    display:none;
}
@media only screen and (min-width: 800px) {
    .show_top {
        margin-top: 10px;
    }
    .show_play {
        display:block;
    }
}

.show_next:hover {
    cursor: pointer;
    background:black;
    color:white;
}

.decide {
    position: absolute;
    top:0;
    z-index: 5;
    width:100%;
    height:100vh;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}



.decide_right, .decide_left {
    width:48%;
    margin-top: 90px;
    border:4px lightgray solid;
    height:75%;
    display:flex;
    margin-left: 5px;
    align-items: center;
    justify-content: center;
    background:rgb(0,0,0,0.95);
    position:relative;
}

.decide_rank {
    width:250px;
    height:50px;
    text-align: center;
    line-height:50px;
    border-radius: 5px;
    font-size: 23px;
    background:white;
    transition:0.4s;
    color:black;
}
.decide_rank:hover,.decide_guess:hover {
    cursor:pointer
}
.decide_rank:hover {
    color:white;
    background:black;
}

.decide_guess:hover {
    color:black;
    background:white;
    border:1px solid black;
}
.decide_guess {
    width:250px;
    transition:0.4s;
    height:50px;
    text-align: center;
    line-height:50px;
    border-radius: 5px;
    font-size: 23px;
    background:black;
    color:white
}

.decide_l , .decide_r {
    position: absolute;
    font-size: 120px;
    top:0;
    
}


.decide_l {
    right:0;
    color:white;
}

.decide_r {
    left:0;
    color:black;
}