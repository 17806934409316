/*! * * * * * * * * * * * * * * * * * * * *\  
  CSShake :: shake-horizontal
  v1.5.0
  CSS classes to move your DOM
  (c) 2015 @elrumordelaluz
  http://elrumordelaluz.github.io/csshake/
  Licensed under MIT
\* * * * * * * * * * * * * * * * * * * * */
.shake-horizontal {
    display: inherit;
    transform-origin: center center; }
  
  .shake-freeze,
  .shake-constant.shake-constant--hover:hover,
  .shake-trigger:hover .shake-constant.shake-constant--hover {
    animation-play-state: paused; }
  
  .shake-freeze:hover,
  .shake-trigger:hover .shake-freeze, .shake-horizontal:hover,
  .shake-trigger:hover .shake-horizontal {
    animation-play-state: running; }
  
  @keyframes shake-horizontal {
    12% {
      transform: translate(6px, 0) rotate(0); }
    24% {
      transform: translate(-7px, 0) rotate(0); }
    42% {
      transform: translate(5px, 0) rotate(0); }
    54% {
      transform: translate(7px, 0) rotate(0); }
    64% {
      transform: translate(8px, 0) rotate(0); }
    76% {
      transform: translate(-9px, 0) rotate(0); }
    84% {
      transform: translate(-5px, 0) rotate(0); }
    92% {
      transform: translate(2px, 0) rotate(0); }
    0%, 100% {
      transform: translate(0, 0) rotate(0); } }
 
.shake-horizontal, .shake-horizontal.shake-freeze, .shake-horizontal.shake-constant {
    animation-name: shake-horizontal;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite; }